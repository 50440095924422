.heading img {
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }
  .NewArrivals h4 {
    font-weight: 400;
  }
  .NewArrivals span {
    color: #e94560;
    font-size: 15px;
    font-weight: 500;
  }
  .NewArrivals .box img {
    width: 100%;
    height: 100%;
  }
  