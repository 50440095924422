.home{
    margin-top: 20px;
}
.homeSlide h1{
    font-size: 45px;
}
.homeSlide p{
    margin:20px 0;
}
.homeSlide ul{
    position: absolute;
    bottom: 20px;
}
.homeSlide ul li{
    border: 2px solid #b8b7b7;
    border-radius: 50%;
    padding: 10px;
}
.homeSlide ul li button{
    background: transparent !important;
    color: transparent !important;
}
.homeSlide ul li button::before{
    font-size: 15px;
    color:aqua;
    position:absolute;
    top:2px;
    left:0;
    margin: auto;
}
.homeSlide .box{
    display: flex !important;
}
.homeSlide .box .left{
    width: 70%;
}
.homeSlide .box .right{
    width: 30%;
}
.homeSlide .box img{
    width: 100%;
    height: 100%; 
}